var zh = require("../../../../libs/zh.json");
export default {
  name: "bar",
  components: {},
  props: {},
  data() {
    return {
      series: [{
        name: "",
        data: []
      }],
      chartOptions: {
        colors: ["#398af7", "#63a3f9", "#b3d1fd"],
        chart: {
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        plotOptions: {
          bar: {
            columnWidth: "40%",
            horizontal: false
          }
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value + "月";
            }
          },
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        name: "产品 A",
        data: [44, 55, 41, 67, 22, 43, 27, 45, 15, 44, 30, 44, 30, 15]
      }, {
        name: "产品 B",
        data: [13, 23, 20, 8, 13, 27, 23, 20, 8, 13, 27, 13, 27, 50]
      }, {
        name: "产品 C",
        data: [11, 17, 15, 15, 21, 14, 17, 15, 15, 21, 14, 21, 14, 55]
      }];
    }
  },
  mounted() {
    this.init();
  }
};