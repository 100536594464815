var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-bar"
  }, [_c('Card', [_c('p', {
    staticClass: "card-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("产品销售统计")]), _c('div', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c('DatePicker', {
    staticStyle: {
      "width": "220px"
    },
    attrs: {
      "type": "year",
      "placeholder": "选择查看的年份"
    }
  })], 1), _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "chart"
  }, [_c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "280",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1), _c('Row', {
    staticClass: "row"
  }, [_c('Col', {
    staticClass: "text",
    attrs: {
      "span": "8"
    }
  }, [_c('h6', {}, [_vm._v("总销售额")]), _c('h3', [_vm._v("￥10,345")])]), _c('Col', {
    staticClass: "text",
    attrs: {
      "span": "8"
    }
  }, [_c('h6', {}, [_vm._v("本月")]), _c('h3', [_vm._v("￥7,589")])]), _c('Col', {
    staticClass: "text",
    attrs: {
      "span": "8"
    }
  }, [_c('h6', {}, [_vm._v("本周")]), _c('h3', [_vm._v("￥1,476")])])], 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };