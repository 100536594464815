import countUp from "@/views/my-components/widget/countUp";
export default {
  name: "card2",
  components: {
    countUp
  },
  props: {
    id: {
      type: String,
      default: "countup"
    },
    icon: {
      type: String,
      default: "https://ooo.0o0.ooo/2019/11/22/OSp4jzy5TrLAvM3.png"
    },
    title: {
      type: String,
      default: "-"
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};