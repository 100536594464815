import cardInfo from "./components/cardInfo.vue";
import LineChart from "./components/line.vue";
import bar from "./components/bar.vue";
import mapCard from "./components/mapCard.vue";
import radar from "./components/radar.vue";
export default {
  name: "dashboard-1",
  components: {
    cardInfo,
    LineChart,
    bar,
    mapCard,
    radar
  },
  data() {
    return {
      icons: {
        icon1: require("@/assets/dashboard/fee.png"),
        icon2: require("@/assets/dashboard/department.png"),
        icon3: require("@/assets/dashboard/console.png"),
        icon4: require("@/assets/dashboard/user.png")
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};