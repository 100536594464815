var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card-info"
  }, [_c('span', {
    staticClass: "lstick"
  }), _c('div', {
    staticClass: "info-wrap"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.icon
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('h6', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.title))]), _c('countUp', {
    staticClass: "mt",
    attrs: {
      "color": "#67757c",
      "countWeight": 400,
      "countSize": "24px",
      "id": _vm.id,
      "endVal": _vm.value
    }
  })], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };