var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dashboard-page"
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardInfo', {
    staticClass: "margin",
    attrs: {
      "id": "income",
      "icon": _vm.icons.icon1,
      "title": "总收入",
      "value": 953000
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardInfo', {
    staticClass: "margin",
    attrs: {
      "id": "out",
      "icon": _vm.icons.icon2,
      "title": "总支出",
      "value": 236000
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardInfo', {
    staticClass: "margin",
    attrs: {
      "id": "order",
      "icon": _vm.icons.icon3,
      "title": "订单总数",
      "value": 987563
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardInfo', {
    staticClass: "margin",
    attrs: {
      "id": "users",
      "icon": _vm.icons.icon4,
      "title": "会员总数",
      "value": 987563
    }
  })], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 12
    }
  }, [_c('LineChart', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 12
    }
  }, [_c('bar', {
    staticClass: "margin"
  })], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 16
    }
  }, [_c('mapCard', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 8
    }
  }, [_c('radar', {
    staticClass: "margin"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };