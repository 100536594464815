var zh = require("../../../../libs/zh.json");
export default {
  name: "line-chart",
  components: {},
  props: {},
  data() {
    return {
      series: [{
        name: "",
        data: []
      }],
      chartOptions: {
        colors: ["#599df9"],
        chart: {
          zoom: {
            enabled: false
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        stroke: {
          width: 3,
          curve: "straight"
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 4
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value + "月";
            }
          },
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        name: "销售金额",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 110, 140, 135]
      }];
    }
  },
  mounted() {
    this.init();
  }
};